<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <svg
    id="ecvWaveArticle"
    class="ecvWaveArticle"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1920 24.1"
    preserveAspectRatio="xMidYMid slice"
  >
    <path
      class="cls-1"
      d="M1467.6,0C1362.4,0,1257.2,3.59,1152,8.39c-39.15,1.82-78.31,3.81-117.46,5.72C945.7,11.93,856.84,10,768,10c-128,0-256,4-384,5.5S128,16,64,15.5L0,15V0Z"
    />
    <path
      class="cls-2"
      d="M1920,0V18.21l-64-3.92c-64-4-192-11.74-320-13.76Q1501.8,0,1467.6,0Z"
    />
    <path
      class="cls-3"
      d="M1034.54,14.11l-24.84,1.21-25,1.17-.7,0q-12,.56-24,1.08l-12,.52q-9.43.4-18.87.78l-11.1.44q-8.34.33-16.67.63L888,20.44l-7.21.25L870.55,21l-6.55.2q-14.34.44-28.68.78L834,22q-15,.36-30,.62h-.42l-4.74.08q-7.1.12-14.17.2-8.34.11-16.67.18c-128,1-256-4.92-384-5.9s-256,3-320,4.9l-64,2V15l64,.5c64,.53,192,1.47,320,0S640,10,768,10C856.84,10,945.7,11.93,1034.54,14.11Z"
    />
    <path class="cls-3" d="M1034.71,14.11l-25,1.21,24.84-1.21Z" />
  </svg>
</template>
<style scoped lang="sass">
  .cls-1 
    fill:#fff
  .cls-2 
    fill:#fff
  .cls-3 
    fill:#ffffffde
    transform: translateY(-0.5px)
</style>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
