import { render, staticRenderFns } from "./ecvWaveArticle.vue?vue&type=template&id=40d1b9d4&scoped=true&"
import script from "./ecvWaveArticle.vue?vue&type=script&lang=js&"
export * from "./ecvWaveArticle.vue?vue&type=script&lang=js&"
import style0 from "./ecvWaveArticle.vue?vue&type=style&index=0&id=40d1b9d4&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d1b9d4",
  null
  
)

export default component.exports