<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <svg
      id="ecvWaveL"
      class="ecvWaveL"
      data-name="ecvWaveL"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 30"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="Path_512"
        data-name="Path 512"
        class="ecvWaveL"
        d="M0,15.49S224.94,0,480.52,0s713.69,30,852.72,30S1756,28,1920,15V30H0Z"
        transform="translate(0 0)"
      />
    </svg>
    <svg
      id="ecvWaveLMobile"
      class="ecvWaveLMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 20"
      preserveAspectRatio="xMidYMid slice"
    >
      <path
        id="Path_515"
        class="cls-1"
        d="M375,7.74C343.94,2.74,312.57,.15,281.15,0,231.23,0,127.3,15,100.15,15S32.03,13.98,0,7.5v12.5H375V7.74Z"
      />
    </svg>
  </div>
</template>
<style scoped lang="sass">
.ecvWaveL
  display: block
  width: 100%
  overflow: hidden
  @media screen and (max-width:900px)
    display: none
.ecvWaveLMobile
  display: none
  @media screen and (max-width:900px)
    display: block
    width: 100vw
.cls-1
  fill: #ffffff
</style>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
