<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div class="owl">
    <carousel
      :key="refreshKey"
      v-bind="options"
      responsiveBaseElement="body"
      @resized="resized"
      class="owl-inner"
    >
      <slot name="items"></slot>
    </carousel>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import carousel from "components/owl/plugin/dist/vue-owl-carousel.js";
export default {
  components: { carousel },
  props: {
    refreshKey: Number,
    options: Object,
    bottomWave: {
      type: String,
    },
    topWave: {
      type: String,
    },
  },
  data: () => ({}),
  computed: {},
  mounted() {

  },
  methods: {
    resized() {
      this.$emit("resize");
    },
  },
};
</script>
