/* Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. */
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import Owl from "./owl.vue";
import EcvWaveR from "@/components/wave/ecvWaveR.vue";
import EcvWaveL from "@/components/wave/ecvWaveL.vue";
import EcvWaveArticle from "@/components/wave/ecvWaveArticle.vue";

export default {
  components: {
    Owl,
    EcvWaveR,
    EcvWaveL
  },
  props: {
    options: {
      type: Object,
    },
    topWave: {
      type: String,
      default: "R",
    },
    bottomWave: {
      type: String,
      default: "R",
    },
    waveWithShadow: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({

  }),
  computed: {
    windowWidth() {
      return this.$store.getters[`base/windowWidth`]
    },
    bottomWaveComponent() {
      if(this.waveWithShadow) {
        return EcvWaveArticle
      }
      if (this.bottomWave == "L") return EcvWaveL;
      if (this.bottomWave == "R") return EcvWaveR;
      return null;
    },
    bottomWaveClass() {
      if (this.bottomWave == "L") return "bottom-wave-l";
      if (this.bottomWave == "R") return "bottom-wave-r";
      return "";
    },
    topWaveComponent() {
      return null;
    },
  },
  mounted() {
    this.setNavIcon();
  },
  methods: {
    setNavIcon() {
      const nextNav = this.$el.querySelector(".owl-next"),
        prevNav = this.$el.querySelector(".owl-prev")
      if (!nextNav && !prevNav) return
      $(nextNav).html(
        '<i class="ri-arrow-right-s-line navIcon"></i>'
      );
      $(prevNav).html(
        '<i class=" ri-arrow-left-s-line navIcon"></i>'
      );
    },
  },
}